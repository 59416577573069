<template>
  <div class="relative bg-white dark:bg-black py-8 sm:py-12 lg:py-16">
    <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h2 class="text-base font-semibold tracking-wider text-orange uppercase">Better meetings</h2>
      <p class="mt-2 text-3xl font-extrabold text-gray-900 dark:text-gray-100 tracking-tight sm:text-4xl">
        Everything you need to organize your meeting
      </p>
      <p class="mt-5 max-w-prose mx-auto text-xl text-gray-500">
        PAJ Meet gets a complete new set of features to take over nasty parts of any meeting.
      </p>
      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          <div v-for="feature in features" :key="feature.name" class="pt-6">
            <div class="flow-root bg-gray-50 dark:bg-gray-900 rounded-lg px-6 pb-8">
              <div class="-mt-6">
                <div>
                  <span class="inline-flex items-center justify-center p-3 bg-orange rounded-md shadow-lg">
                    <component :is="feature.icon" class="h-6 w-6 text-white dark:text-black" aria-hidden="true" />
                  </span>
                </div>
                <h3 class="mt-8 text-lg font-medium text-gray-900 dark:text-gray-100 tracking-tight">{{ feature.name }}</h3>
                <p class="mt-5 text-base text-gray-500">
                  {{feature.desc}}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BookOpenIcon,
  ViewListIcon,
  ClockIcon,
} from '@heroicons/vue/outline'

const features = [
  { name: 'Easy to find', icon: BookOpenIcon, desc: "Make use of the public room directory to discover meetings."},
  { name: 'Queue & Present', icon: ViewListIcon, desc: "Organize presentation of persons and topics with ease." },
  { name: 'Watch your time', icon: ClockIcon, desc: "Set times and schedule specific parts of your meeting."},
]

export default {
  setup() {
    return {
      features,
    }
  },
}
</script>

<style scoped>

</style>