<template>
  <div class="text-center h-36 w-full flex flex-col items-center justify-center">
    <div class="space-y-0 space-x-4 sm:mx-auto sm:inline-grid sm:grid-cols-2">
    <button @click="emitEditor('TOPIC')" type="button" class="relative block flex-grow border-2 border-white border-dashed rounded-lg p-3 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
      <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
        <ViewGridAddIcon />
      </svg>
      <span class="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-100">
      Add topic
    </span>
    </button>

    <button @click="emitEditor('PRESENTER')" type="button" class="relative block flex-grow border-2 border-white border-dashed rounded-lg p-3 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
      <svg class="mx-auto h-6 w-6 text-gray-400" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
        <UserAddIcon />
      </svg>
      <span class="mt-2 block text-sm font-medium text-gray-900 dark:text-gray-100">
      Add presenter
    </span>
    </button>
    </div>
    <div class="flex flex-row flex-grow items-center justify-center">
      <span class="relative z-0 inline-flex shadow-sm rounded-md">
        <button @click="sendStateChangeRequest('START')" :class="getStylePlay" type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange">
          <Component :is="getStartButtonIcon" class="w-5 h-5 inline mr-1" /> {{getStartButtonFunctionText}}
        </button>
        <button @click="sendStateChangeRequest('PAUSE')" :class="getStylePause" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange">
          <PauseIcon class="w-5 h-5 inline mr-1" /> Pause
        </button>
        <button @click="sendStateChangeRequest('STOP')" :class="getStyleStop" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange">
          <StopIcon class="w-5 h-5 inline mr-1" /> Stop
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import {PlayIcon, StopIcon, PauseIcon, ArrowCircleRightIcon} from "@heroicons/vue/solid/esm";
import {UserAddIcon, ViewGridAddIcon} from "@heroicons/vue/outline/esm";
import api from "@/api";

const onPlay = ["bg-orange", "text-white", "hover:text-white"];
const onPrep = ["bg-orange", "text-white", "hover:text-white"];
const onPause = ["bg-orange", "text-white", "hover:text-white"];
const onStop = ["bg-orange", "text-white", "hover:text-white"];
const onDefault = ["bg-white", "text-gray-700", "hover:bg-gray-50", "hover:text-black"];

export default {
  name: "QueueControl",
  components: {
    PlayIcon,
    StopIcon,
    PauseIcon,
    UserAddIcon,
    ViewGridAddIcon
  },
  methods: {
    sendStateChangeRequest(buttonsource) {
      let url = "/queue/" + this.queue.id + "/";
      if(buttonsource === "START" && this.state === "RUNNING") {
        url = url + "next";
      } else if(buttonsource === "START" && this.state !== "RUNNING") {
        url = url + "start";
      } else if(buttonsource === "PAUSE" && this.state !== "PAUSED") {
        url = url + "pause";
      } else if(buttonsource === "STOP" && this.state !== "STOPPED") {
        url = url + "stop";
      } else {
        return;
      }
      api.put(url).then((response =>  {
        if(response.status === 202) {
          console.log("Successfully send change request to " + url);
        }
      })).catch(error => {
        console.log("Error while requesting queue state change");
        console.log(error);
      });
    },
    emitEditor(type) {
      this.$emit("editoropen", type);
    }
  },
  computed: {
    getStylePlay() {
      if(this.state === "RUNNING") return onPlay;
      if(this.state === "PREPARING") return onPrep;
      return onDefault;
    },
    getStylePause() {
      if(this.state === "PAUSED") return onPause;
      return onDefault;
    },
    getStyleStop() {
      if(this.state === "STOPPED") return onStop;
      return onDefault;
    },
    getStartButtonFunctionText() {
      if(this.state === "RUNNING") {
        return "Skip"
      } else if( this.state === "PREPARING") {
        return "Wait"
      } else {
        return "Start"
      }
    },
    getStartButtonIcon() {
      if(this.state === "RUNNING") {
        return ArrowCircleRightIcon;
      } else {
        return PlayIcon;
      }
    }
  },
  emits: {
    editoropen: null
  },
  props: {
    state: {
      type: String,
      required: true,
      default: "PREPARING"
    },
    queue: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>

</style>