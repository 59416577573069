<template>

  <UserIcon v-if="icon === 'UserIcon'"/>
  <SpeakerphoneIcon v-else-if="icon === 'SpeakerphoneIcon'" />
  <ThumbUpIcon v-else-if="icon === 'ThumbUpIcon'" />
  <CurrencyEuroIcon v-else-if="icon === 'CurrencyEuroIcon'" />
  <CheckIcon v-else-if="icon === 'CheckIcon'" />
  <QuestionMarkCircleIcon v-else />

</template>

<script>
import {
  CheckIcon,
  CurrencyEuroIcon,
  QuestionMarkCircleIcon,
  SpeakerphoneIcon,
  ThumbUpIcon,
  UserIcon
} from "@heroicons/vue/solid";

export default {
  name: "QueueItemIcon",
  props: {
    icon: {
      type: String,
      required: true,
      default: "UserIcon"
    }
  },
  components: {
    QuestionMarkCircleIcon,
    UserIcon,
    ThumbUpIcon,
    CurrencyEuroIcon,
    CheckIcon,
    SpeakerphoneIcon
  }
}
</script>

<style scoped>

</style>