<template>

  <button @click="chooseMeeting(meeting)" v-for="meeting in meetings" :key="meeting.name" type="button" class="relative inline-flex items-center px-6 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
    {{ meeting.name }}
    <div v-if="meeting.participants > 0" class="absolute top-0 right-0 -mt-4 -mr-4 px-4 py-1 bg-orange-light rounded-full">{{meeting.participants}}</div>
  </button>

</template>

<script>

import api from "@/api";

const meetings = [
    {
      name: "Demo",
      participants: 5,
      started: 1626613814,
      server: { name: 'Standard Meeting', desc: "default", limit: 'Up to 50 participants', url: "https://meet.paj-gps.com/" }
    }
];

export default {
  name: "Meetings",
  data() {
    return {
      meetings: meetings
    }
  },
  methods: {
    chooseMeeting(meeting) {
      this.$emit('cmeeting', meeting);
    }
  },
  emits: {
    cmeeting: null
  },
  beforeMount() {
    api.get("/room").then(response => {
      if(response.status === 200) {
        this.meetings = response.data;
      }
    })
  }
}
</script>

<style scoped>

</style>