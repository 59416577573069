<template>
  <div class="text-gray-500 bg-white text-gray-700 dark:text-white dark:bg-gray-700 text-center w-20 p-0.5  rounded-sm">
    <ClockIcon class="w-5 h-5 inline -mt-1 -ml-0.5" /> {{formatTime}}
  </div>
</template>

<script>
import {ClockIcon} from "@heroicons/vue/outline";

export default {
  name: "QueueClock",
  computed: {
    formatTime() {
      let minutes = ~~(this.seconds / 60);
      let seconds = this.seconds % 60;
      if(minutes < 10) minutes = "0" + minutes;
      if(seconds < 10) seconds= "0" + seconds;
      return minutes + ":" + seconds;
    }
  },
  components: {
    ClockIcon
  },
  props: {
    seconds: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>

</style>