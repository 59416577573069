<template>
  <!--<ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"> -->
    <div class="col-span-1 bg-white dark:bg-gray-700 rounded-lg shadow divide-y divide-gray-200 mt-2">
      <div class="relative w-full flex items-center justify-between p-1">
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3 px-2">
            <h3 class="text-gray-900 dark:text-white text-sm font-medium truncate">{{getDisplayName}}</h3>
            <span v-if="isAdmin" class="flex-shrink-0 inline-block px-2 py-0.5 text-white text-xs font-medium bg-orange rounded-full">Host</span>
          </div>
          <!--<p class="mt-1 text-gray-500 text-sm truncate">{{ person.title }}</p>-->
        </div>
        <img class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0" :src="require('@/assets/pbunknown200px.png')" alt="" />
        <span class="absolute top-0 right-1 mt-1 ml-2 h-3 w-3 rounded-full ring-2 ring-white dark:ring-gray-700" :class="getDotColor" />
        <span v-if="status === 'ONLINE'" class="absolute animate-ping top-0 right-1 mt-1 ml-2 h-3 w-3 rounded-full ring-2 ring-white" :class="getDotColor"/>
      </div>
    </div>
  <!--</ul> -->
</template>

<script>

import api from "@/api";

const dotColorOnline = ['bg-green-300'];
const dotColorOffline= ['bg-red-600'];
const dotColorLost= ['bg-gray-900'];

export default {
  name: "UserCard",
  components: {
  },
  data() {
    return {
      username: null
    }
  },
  computed: {
    getDotColor() {
      if(this.status === "ONLINE") return dotColorOnline;
      if(this.status === "OFFLINE") return dotColorOffline;
      if(this.status === "LOST") return dotColorLost;
      return dotColorLost;
    },
    getDisplayName() {
      if(this.username === null) return this.id;
      return this.username;
    }
  },
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
  },
  mounted() {
    if(this.username === null) {
      api.get("/user/" + this.id).then(response => {
        if(response.status === 200) {
          this.username = response.data.name;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>