import {createStore} from "vuex";
// eslint-disable-next-line no-unused-vars
import api from "@/api";

const store = createStore({
    state() {
        return {
            user: null,
        }
    },
    mutations: {
        updateUser(state, payload) {
            state.user = payload;
            api.defaults.headers.common['user'] = payload.id;
        }
    },
    getters: {
        loginUser(state) {
            return state.user;
        }
    },
    actions: {
        setUser(context, payload) {
            context.commit("updateUser", payload);
        }
    }
});

export default store;