import {createApp} from 'vue/dist/vue.esm-bundler.js'
import './index.css'
import {createRouter, createWebHistory} from "vue-router";
import store from "@/store";
import MainPage from "@/sites/MainPage";
import LoadScript from "vue-plugin-load-script";


const app = createApp({});

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: MainPage,
    },
    {
      path: '/join/:name',
      component: MainPage,
    }
  ]
});

//store.commit('updateUser', JSON.parse(localStorage.getItem("user")));

app.use(router);
app.use(store);
app.use(LoadScript);
app.mount('#app');

export {router};