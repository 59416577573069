<template>
  <div class="w-5/12 h-32 text-center flex-wrap flex content-evenly items-center justify-center space-x-2 rounded-md">
    <div>
      <transition name="slide" mode="out-in">
        <QueueItemIcon v-if="showIcon" :icon="getIcon" class="w-12 h-12 text-white"/>
      </transition>
    </div>
    <div>
      <transition name="slide" mode="out-in">
        <div v-if="showContent" class="font-sans text-xl text-white">
          {{getContent}}
        </div>
        </transition>
      <transition name="slide" mode="out-in">
        <div v-if="showTarget" class="font-sans font-bold text-2xl text-white">
          {{getTarget}}
        </div>
      </transition>
      <transition name="slide" mode="out-in">
        <div v-if="showTime" class="grid justify-items-stretch">
          <QueueClock :seconds="queueItem.time" class="justify-self-center"></QueueClock>
        </div>
      </transition>
    </div>
  </div>

</template>

<script>
import QueueItemIcon from "@/components/QueueItemIcon";
import QueueClock from "@/components/QueueClock";
export default {
  name: "TopicAnnouncement",
  components: {QueueClock, QueueItemIcon},
  data() {
    return {
      tick: 0,
      interval: null
    }
  },
  props: {
    queueItem: {
      required: true
    }
  },
  computed: {
    getTarget() {
      if(this.queueItem === undefined || this.queueItem === null) return "no target";
      return this.queueItem.target;
    },
    getContent() {
      if(this.queueItem === undefined || this.queueItem === null) return "no content";
      return this.queueItem.content;
    },
    getTime() {
      if(this.queueItem === undefined || this.queueItem === null) return -1;
      return this.queueItem.content;
    },
    showTime() {
      if(this.queueItem === undefined || this.queueItem === null) return false;
      if(this.queueItem.type === "PRESENTER") return false;
      return (this.queueItem.time !== -1 && this.tick > 3);
    },
    showContent() {
      if(this.queueItem.content !== undefined && this.queueItem.content !== null) {
        return (this.queueItem.content.length > 3 && this.tick > 2);
      }
      return false;
    },
    showTarget() {
      return (this.tick > 3);
    },
    getIcon() {
      if(this.queueItem === undefined || this.queueItem === null) return "UserIcon";
      return this.queueItem.icon;
    },
    showIcon() {
      return this.tick > 0;
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.tick++;
    }, 500);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  }
}
</script>

<style scoped>

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s ease, opacity 0.4s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>