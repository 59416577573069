<template>
  <div class="min-h-screen bg-orange-dark flex flex-col" :class="{dark: useDarkMode}">
    <header>
      <Popover class="relative bg-orange-dark dark:bg-gray-900">
        <div class="flex justify-between items-center mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8" :class="{'max-w-7xl': currentState !== 4}">
          <div class="flex justify-start lg:w-0 lg:flex-1">
              <img class="h-8 w-auto sm:h-10" :src="require('@/assets/iconweiss.svg')" alt="" />
              <div class="relative text-white text-sm text-center text-xl font-extrabold tracking-tight ml-2 mt-1.5">{{name}}</div>
              <transition name="slide" mode="out-in">
                <div v-if="livemeetingname !== null && currentState === 4" class="relative text-white text-sm text-center text-xl font-extrabold tracking-tight ml-3 mt-1.5"><UserGroupIcon class="h-6 w-6 -mt-2 inline" /> {{livemeetingname}}</div>
              </transition>

          </div>


          <div v-if="showTopButtons" class="flex justify-end lg:w-0 lg:flex-1 space-x-2">

            <button @click="copyRoomLink" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white dark:border-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
              <ShareIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              copy link
            </button>

            <button @click="addSelfPresentation" v-if="canAddSelf" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white dark:border-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
              <ViewGridAddIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              add me to presenters
            </button>

            <button v-if="!canAddSelf" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white dark:border-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
              <ViewGridAddIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              already in queue
            </button>

            <button @click="queueDeleteAll" v-if="showAdminButtons" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white dark:border-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
              <TrashIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              delete queue
            </button>

            <button @click="endMeeting" v-if="showAdminButtons" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white dark:border-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
              <PhoneMissedCallIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
              end meeting
            </button>
          </div>

        </div>

        <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
          <PopoverPanel focus class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-black divide-y-2 divide-gray-50">
              <div class="pt-5 pb-6 px-5">
                <div class="flex items-center justify-between">
                  <div>
                    <img class="h-8 w-auto" :src="require('@/assets/iconweiss.svg')" alt="PAJ" />
                  </div>
                  <div class="-mr-2">
                    <PopoverButton class="bg-white dark:bg-black rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
                <div class="mt-6">
                  <nav class="grid grid-cols-1 gap-7">
                    <a v-for="item in solutions" :key="item.name" :href="item.href" class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                      <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-600 text-white">
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div class="ml-4 text-base font-medium text-gray-900">
                        {{ item.name }}
                      </div>
                    </a>
                  </nav>
                </div>
              </div>
              <div class="py-6 px-5">
                <div class="grid grid-cols-2 gap-4">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-base font-medium text-gray-900 hover:text-gray-700">
                    {{ item.name }}
                  </a>
                </div>
                <div class="mt-6">
                  <a href="#" class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700">
                    Sign up
                  </a>
                  <p class="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?
                    <a href="#" class="text-gray-900">
                      Sign in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </header>

    <transition name="slide" mode="out-in">
    <main v-if="currentState !== 4 && currentState !== 6" class="flex flex-col flex-grow">
      <div>
        <!-- Hero card -->
        <div class="relative mt-10">
          <div class="absolute inset-x-0 bottom-0 h-1/2 bg-orange-dark" />
          <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div class="absolute inset-0">
                <img class="h-full w-full object-cover" :src="require('@/assets/welcome-background2.png')" alt="PAJ Meet Background" />
                <div class="absolute inset-0 bg-orange mix-blend-multiply opacity-40 dark:bg-black" />
              </div>
              <transition name="slide" mode="out-in">
              <div v-if="currentState===0" class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span class="block text-white ">Take control of your</span>
                  <span class="block text-white">meetings</span>
                </h1>
                <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                  The new connected experience of PAJ Meet
                </p>
                <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a @click="showListView" href="#" :class="listButtonStyle" class="relative flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm sm:px-8">
                      {{roomsAvailable}}
                      <span v-if="liveMeetingsAvailable" class="absolute top-0 left-0 -mt-2 -ml-2 h-4 w-4 rounded-full ring-2 ring-white bg-orange-light" />
                      <span v-if="liveMeetingsAvailable" class="absolute animate-ping top-0 left-0 -mt-2 -ml-2 h-4 w-4 rounded-full ring-2 ring-white bg-orange-light" />
                    </a>

                    <a @click.prevent="showCreateDView" href="#" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange bg-opacity-80 hover:bg-opacity-100 sm:px-8">
                      Create a new room
                    </a>
                  </div>
                </div>
              </div>

                <div v-else-if="currentState===1" class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 class="text-center text-3xl font-extrabold tracking-tight">
                    <span class="block text-white">Create a new Meeting room</span>
                    <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                      Administrative features will be granted
                    </p>
                  </h1>
                  <RadioGroup v-model="selected" class="mt-10">
                    <div class="relative bg-white rounded-md -space-y-px">
                      <RadioGroupOption as="template" v-for="(server, svrIdx) in meetingservers" :key="server.name" :value="server" v-slot="{ checked, active }">
                        <div :class="[svrIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '', svrIdx === meetingservers.length - 1 ? 'rounded-bl-md rounded-br-md' : '', checked ? 'bg-orange border-orange z-10' : 'border-gray-200', 'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none']">
                          <div class="flex items-center text-sm">
              <span :class="[checked ? 'bg-orange border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-orange' : '', 'h-4 w-4 rounded-full border flex items-center justify-center']" aria-hidden="true">
                <span class="rounded-full bg-white w-1.5 h-1.5" />
              </span>
                              <RadioGroupLabel as="span" :class="[checked ? 'text-white' : 'text-gray-900', 'ml-3 font-medium']">{{ server.name }}</RadioGroupLabel>
                          </div>
                          <RadioGroupDescription class="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
                            <span :class="[checked ? 'text-white' : 'text-gray-900', 'font-medium']">{{
                                server.desc
                              }}</span>
                            {{ ' ' }}
                          </RadioGroupDescription>
                          <RadioGroupDescription :class="[checked ? 'text-white' : 'text-gray-500', 'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right']">{{ server.limit }}</RadioGroupDescription>
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>

                  <form class="mt-5 flex flex-col items-center">
                    <div class="max-w-md">
                      <input v-model="newroomname" type="text" class="shadow-sm focus:ring-orange focus:border-orange inline-block w-12/12 sm:text-sm border-gray-300 rounded-md" placeholder="RoomName" />
                      <button @click.prevent="createRoom" type="submit" class="mt-3 inline-block justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                        Create
                      </button>
                    </div>

                  </form>
                </div>

                <div v-else-if="currentState===2" class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 class="text-center text-3xl font-extrabold tracking-tight">
                    <span class="block text-white">There are meetings available</span>
                  </h1>
                  <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                    Choose the meeting you want to join
                  </p>
                  <div class="mt-10 mx-auto max-w-none flex justify-center">
                    <div class="space-y-4 space-x-8 mx-auto grid-flow-col auto-cols-max justify-self-center">
                      <Meetings @cmeeting="showJoinView"></Meetings>
                    </div>
                  </div>
                </div>

                <div v-else-if="currentState===3" class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 class="text-center text-4xl font-extrabold tracking-tight">
                    <span class="block text-white">Joining {{joinmeetingname}}..</span>
                  </h1>
                  <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                    Please wait <PulseLoader color="#ff4e00"></PulseLoader>
                  </p>
                </div>

                <div v-else-if="currentState===5" class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                  <h1 class="text-center text-4xl font-extrabold tracking-tight">
                    <span class="block text-white">Meeting {{joinmeetingname}} ended</span>
                  </h1>
                  <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                    Thank you for using PAJ Meet! 🎉
                  </p>
                </div>
              </transition>

            </div>
          </div>
        </div>

      </div>

      <!-- More main page content here... -->

      <Features class="flex-grow mt-10"></Features>


      <!-- Logo cloud -->
      <div class="flex-none bg-gray-900">
        <div class="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
          <p class="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
            PAJ Meet based on Jitsi,<br>enhanced by PAJ<br>your unique identifier: {{getUUID}}
          </p>
        </div>
      </div>
    </main>

      <main v-else-if="currentState === 4" class="flex flex-row flex-grow">
        <div class="flex-grow flex flex-row">
          <MeetingView @onframeload="processOnLoad" @canaddself="allowAddSelf" :iframesource="iframesource" :room="currentroom" />
        </div>

      </main>

      <main v-else-if="currentState === 6" class="flex flex-col flex-grow">

        <div class="relative">
          <div class="absolute inset-x-0 bottom-0 h-1/2 bg-orange-dark" />
          <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div class="bg-white shadow sm:rounded-lg dark:bg-black">
                <div class="px-4 py-5 sm:p-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                    Update your Name
                  </h3>
                  <div class="mt-2 max-w-xl text-sm text-gray-500">
                    <p>
                      You have to type in your <b>full name</b> in order to proceed.
                    </p>
                  </div>
                  <form class="mt-5 sm:flex sm:items-center">
                    <div class="w-full sm:max-w-xs">
                      <input v-model="name" type="text" class="shadow-sm focus:ring-orange focus:border-orange block w-full sm:text-sm border-gray-300 rounded-md" placeholder="John Doe" />
                    </div>
                    <button @click.prevent="saveName" type="submit" class="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                      Save
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    </transition>

  </div>
</template>

<script>
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel, RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel, RadioGroupOption
} from '@headlessui/vue'
import {
  AnnotationIcon,
  ChatAlt2Icon,
  InboxIcon,
  MenuIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from '@heroicons/vue/outline'
import {ChevronDownIcon, PhoneMissedCallIcon, ShareIcon, TrashIcon, UserGroupIcon} from '@heroicons/vue/solid'
import Features from "@/components/Features";
import Meetings from "@/components/Meetings";
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import MeetingSideOverlay from "@/components/MeetingSideOverlay";
import {mapActions, mapGetters} from "vuex";
import MeetingView from "@/components/MeetingView";
import api from "@/api";
import useClipboard from 'vue-clipboard3';
import {ViewGridAddIcon} from "@heroicons/vue/outline/esm";

const mbtnavailable = ["text-orange","bg-white","hover:bg-orange","hover:text-white", "dark:bg-black"];
const mbtnunavailable = ["text-white","bg-gray-500","hover:bg-gray-600","hover:text-white"];

const solutions = [
  {
    name: 'Inbox',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: InboxIcon,
  },
  {
    name: 'Messaging',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '#',
    icon: AnnotationIcon,
  },
  { name: 'Live Chat', description: "Your customers' data will be safe and secure.", href: '#', icon: ChatAlt2Icon },
  {
    name: 'Knowledge Base',
    description: "Connect with third-party tools that you're already using.",
    href: '#',
    icon: QuestionMarkCircleIcon,
  },
]
const navigation = [
  { name: 'Pricing', href: '#' },
  { name: 'Partners', href: '#' },
  { name: 'Company', href: '#' },
]

const meetingservers = [
  { name: 'Standard Meeting', desc: "default", limit: 'Up to 50 participants', url: "https://meet.paj-gps.com/" },
  { name: 'HD Meeting', desc: "high-definition", limit: 'Up to 6 participants - requires login', url: "https://meetings.paj-gps.de/" }
]

export default {
  components: {
    MeetingView,
    MeetingSideOverlay,
    Meetings,
    Features,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    UserGroupIcon,
    PulseLoader,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    RadioGroup,
    ViewGridAddIcon,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
    PhoneMissedCallIcon,
    TrashIcon,
    ShareIcon,
    useClipboard
  },
  data() {
    return {
      liveMeetingsAvailable: true,
      instanceToUse: null,
      joinmeetingname: null,
      livemeetingname: null,
      currentroom: null,
      name: null,
      newroomname: "",
      selected: 0,
      canAddSelf: false,
      meetingActive: false,
      currentState: 0, // 0=mainMenu 1=create 2=list //3=loading
      meetingservers: meetingservers,
      directJoin: null
    }
  },
  computed: {
    roomsAvailable() {
      return this.liveMeetingsAvailable ? "show available rooms" : "no rooms available";
    },
    listButtonStyle() {
      return this.liveMeetingsAvailable ? mbtnavailable : mbtnunavailable;
    },
    getUUID() {
      if(this.loginUser === undefined) return "unknown";
      if(this.loginUser === null) return "unknown";
      return this.loginUser.id;
    },
    showAdminButtons() {
      if(!this.meetingActive) return false;
      if(this.currentroom === null) return false;
      if(this.loginUser === null ||this.loginUser === undefined) return false;
      return this.currentroom.administrators.includes(this.loginUser.id);
    },
    showTopButtons() {
      return this.meetingActive;
    },
    iframesource() {
      var meetsd = "https://meet.paj-gps.com/";
      // eslint-disable-next-line no-unused-vars
      var meethd = "https://meetings.paj-gps.de/";
      return meetsd + this.joinmeetingname;
    },
    useDarkMode() {
      const d = new Date();
      return (d.getHours() >= 17 || d.getHours() <=7);
    },
    ...mapGetters(["loginUser"])
  },
  methods: {
    showCreateDView() {
      this.currentState = 1;
    },
    showListView() {
      if(this.liveMeetingsAvailable) {
        this.currentState = 2;
      }
    },
    allowAddSelf() {
      this.canAddSelf = true;
    },
    showMainView() {
      this.currentState = 0;
    },
    addSelfPresentation() {
      if(this.canAddSelf) {
        api.post("/queue/" + this.currentroom.queue + "/items/addself").then(value => {
          if(value.status === 202) {
            this.canAddSelf = false;
          }
        }).catch(reason => {
          console.log(reason);
          this.canAddSelf = false;
        })
      } else {
        console.log("not allowed to add self")
      }

    },
    queueDeleteAll() {
      api.delete("/queue/" + this.currentroom.queue + "/items").then((response) => {
        if(response.status === 202) {
          console.log("sucessfully deleted all queue items")
        }
      });
    },
    endMeeting() {
      api.put("/room/" + this.currentroom.id + "/end").then((response) => {
        if(response.status === 202) {
          console.log("sucessfully sent meeting end request");
        }
      })
    },
    copyRoomLink() {
      useClipboard().toClipboard("https://letsmeet.paj-gps.de/join/"+this.currentroom.name).then(() => {
        console.log("Copied to clipboard: " + "https://letsmeet.paj-gps.de/join/"+this.currentroom.name);
      })
    },
    tryDirectJoin(name) {
      api.get("/room").then(response => {
        if(response.status === 200) {
          let finalroom = response.data.find(room => room.name === name);
          if(finalroom !== undefined) {
            this.showJoinView(finalroom);
          } else {
            this.showListView();
          }
        }
      });
    },
    createRoom() {
      if(!this.newroomname.includes(" ")) {
        if(this.selected !== 0) {
          if(this.newroomname.length >= 2) {
            api.post("/room", {
              url: this.selected.url + this.newroomname,
              name: this.newroomname
            }).then(response => {
              if(response.status === 202) {
                this.showListView();
              }
            }).catch(error => {
              alert("Error while creating new room: " + this.newroomname + ". This name may already be taken");
              console.log(error);
            });
          }
          console.log("creating room " + this.newroomname);
        } else {
          alert("please select a valid meeting server");
        }
      } else {
        alert("please remove empty whitespaces from room name");
      }
    },
    showJoinView(meeting) {
      this.joinmeetingname = meeting.name;
      this.instanceToUse = meeting.url;
      this.currentroom = meeting;
      this.currentState = 3;
      setTimeout(() => {
        this.currentState = 4;
      }, 2000);
    },
    processOnLoad() {
      console.log("onload")
      if(!this.meetingActive) {
        this.meetingActive = true;
        this.livemeetingname = this.joinmeetingname;
      } else {
        this.meetingActive = false;
        this.currentState = 5;
        this.livemeetingname = null;
        setTimeout(() => {
          this.currentState = 0;
        }, 4000);
      }
    },
    saveName() {
      if(this.name.length > 6) {
        api.post("/user", {
          name: this.name
        }).then(response => {
          if(response.status === 202) {
            localStorage.setItem("user", JSON.stringify(response.data));
            this.setUser(response.data);
          }
        });
        if(this.directJoin === undefined) {
          this.showMainView();
        } else {
          this.tryDirectJoin(this.directJoin);
        }
      }
    },
    ...mapActions(['setUser'])
  },
  mounted() {

    this.directJoin = this.$route.params.name;

    setTimeout(() => {
      let user = JSON.parse(localStorage.getItem("user"));
      if(user !== null) {
        this.name = user.name;
        this.setUser(user);
        if(this.directJoin !== undefined) {
          this.tryDirectJoin(this.directJoin);
        }
      } else {
        this.currentState = 6;
      }
    }, 500);
  },
  setup() {
    return {
      solutions,
      navigation,
    }
  },
}
</script>

<style scoped>

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s ease, opacity 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.slided-enter-active,
.slided-leave-active {
  transition: transform 0.4s ease, opacity 0.2s ease;
}

.slided-enter-from,
.slided-leave-to {
  transform: translateY(40px);
  opacity: 0;
}

</style>