<template>

  <div class="flow-root w-full h-0 flex-grow">
    <div class="flex flex-row justify-center h-full">
      <form class="h-full divide-y divide-gray-200 flex flex-col bg-white dark:bg-gray-900 shadow-xl w-full">
        <div class="flex-1 h-0 overflow-y-auto">
          <div class="py-6 px-4 bg-orange sm:px-6">
            <div class="flex items-center justify-between">
              <div class="text-lg font-medium text-white">
                {{mode === 0 ? "Update" : "New"}} Element
              </div>
              <div class="ml-3 h-7 flex items-center">
                <button @click="requestClose" type="button" class="bg-orange-light rounded-md text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div class="mt-1">
              <p class="text-sm text-gray-100">
                {{getHeaderText}}
              </p>
            </div>
          </div>
          <div class="flex-1 flex flex-col justify-between">
            <div class="px-4 divide-y divide-gray-200 sm:px-6">
              <div class="pt-6 pb-5" :class="mode !== 0 ? 'space-y-6' : 'space-y-1'">
                <div>
                  <RadioGroup v-if="mode !== 0" v-model="selected">
                    <div class="space-y-4">
                      <RadioGroupOption as="template" v-for="type in types" :key="type.name" :value="type" v-slot="{ active, checked }">
                        <div :class="[active ? 'ring-1 ring-offset-2 ring-orange' : '', 'relative block rounded-lg border border-gray-300 bg-white dark:bg-gray-700 dark:text-gray-100 shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none']">
                          <div class="flex items-center">
                            <div class="text-sm">
                              <RadioGroupLabel as="p" class="font-medium text-gray-900 dark:text-white">
                                <Component :is="type.icon" class="h-5 w-5 inline -mt-1 dark:text-white" /> {{ type.name }}
                              </RadioGroupLabel>
                              <RadioGroupDescription as="div" class="text-gray-500">
                                <p class="sm:inline dark:text-gray-300">{{ type.desc }}</p>
                                {{ ' ' }}
                              </RadioGroupDescription>
                            </div>
                          </div>
                          <div :class="[checked ? 'border-orange' : 'border-transparent', 'absolute -inset-px rounded-lg border-2 pointer-events-none']" aria-hidden="true" />
                        </div>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                </div>
                <div v-if="selected.type === 'TOPIC'">
                  <div>
                    <label for="topic-name" class="block text-sm font-medium text-gray-900 dark:text-gray-100">
                      Name
                    </label>
                    <div class="mt-1">
                      <input v-model="nttarget" type="text" name="topic-name" id="topic-name" class="block w-full shadow-sm sm:text-sm focus:ring-orange focus:border-orange border-gray-300 rounded-md" />
                    </div>
                  </div>
                  <div>
                    <label for="topic-time" class="block text-sm font-medium text-gray-900 mt-2 dark:text-gray-100">
                      Time (seconds)
                    </label>
                    <div class="mt-1">
                      <SwitchGroup as="div" class="flex items-center mb-2">
                        <Switch v-model="nttimeinfinite" :class="[nttimeinfinite ? 'bg-orange' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none']">
                          <span aria-hidden="true" :class="[nttimeinfinite ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                        </Switch>
                        <SwitchLabel as="span" class="ml-3">
                          <span class="text-sm font-medium text-gray-900 dark:text-gray-100"> Endless time </span>
                          <span class="text-sm text-gray-500"></span>
                        </SwitchLabel>
                      </SwitchGroup>
                      <input v-model="nttime" type="number" name="topic-time" id="topic-time" class="block w-full shadow-sm sm:text-sm focus:ring-orange focus:border-orange border-gray-300 rounded-md" :disabled="nttimeinfinite" :class="[nttimeinfinite ? 'bg-gray-100 text-gray-100' : '']"/>
                    </div>
                  </div>

                  <div class="mt-4 flex text-sm">
                    <span class="group inline-flex items-center text-gray-500 dark:text-gray-200">
                      <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400 dark:text-gray-200" aria-hidden="true" />
                      <span class="ml-2 dark:text-gray-100">
                        Only room administrators can skip to the current topic.
                      </span>
                    </span>
                  </div>

                </div>
                <div v-else-if="selected.type === 'PRESENTER'">

                  <div>
                    <label for="target-name" class="block text-sm font-medium text-gray-900 dark:text-gray-100">
                      Presenter
                    </label>
                    <div class="mt-1">
                      <!--<input v-model="nptarget" type="text" name="target-name" id="target-name" class="block w-full shadow-sm sm:text-sm focus:ring-orange focus:border-orange border-gray-300 rounded-md" />-->
                      <Listbox as="div" v-model="nptarget">
                        <ListboxLabel class="block text-sm font-medium text-gray-700">
                        </ListboxLabel>
                        <div class="mt-1 relative">
                          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-orange focus:border-orange sm:text-sm">
                            <span class="block truncate">{{ getNPTarget }}</span>
                            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                          </ListboxButton>

                          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                              <ListboxOption as="template" v-for="user in getUserList" :key="user.id" :value="user.id" v-slot="{ active, selected }">
                                <li :class="[active ? 'text-white bg-orange' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                  {{ user.name }}
                                </span>

                                  <span v-if="selected" :class="[active ? 'text-white' : 'text-orange', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                  </span>
                                </li>
                              </ListboxOption>
                            </ListboxOptions>
                          </transition>
                        </div>
                      </Listbox>
                    </div>
                  </div>
                  <div>
                    <label for="presenter-time" class="block text-sm font-medium text-gray-900 mt-2 dark:text-gray-100">
                      Time (seconds)
                    </label>
                    <div class="mt-1">
                      <SwitchGroup as="div" class="flex items-center mb-2">
                        <Switch v-model="nptimeinfinite" :class="[nptimeinfinite ? 'bg-orange' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none']">
                          <span aria-hidden="true" :class="[nptimeinfinite ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                        </Switch>
                        <SwitchLabel as="span" class="ml-3">
                          <span class="text-sm font-medium text-gray-900 dark:text-gray-100"> Endless time </span>
                          <span class="text-sm text-gray-500"></span>
                        </SwitchLabel>
                      </SwitchGroup>
                      <input v-model="nptime" type="number" name="presenter-time" id="presenter-time" class="block w-full shadow-sm sm:text-sm focus:ring-orange focus:border-orange border-gray-300 rounded-md" :disabled="nptimeinfinite" :class="[nptimeinfinite ? 'bg-gray-100 text-gray-100' : '']"/>
                    </div>
                  </div>

                  <div class="mt-4 flex text-sm">
                    <span class="group inline-flex items-center text-gray-500 ">
                      <QuestionMarkCircleIcon class="h-10 w-10 text-gray-400 dark:text-gray-300" aria-hidden="true" />
                      <span class="ml-2 dark:text-gray-300">
                        Only room administrators and the presenting user can skip/end to the presentation.
                      </span>
                    </span>
                  </div>

                  <div class="flex items-center justify-center mt-5">
                    <button @click="createPresentationAll" type="button" class="inline-flex px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <LightningBoltIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                      <span v-if="!createAllRunning">Create presentation for everyone in this meeting</span>
                      <span v-else>Creating user presentations.. ({{createAllSize}} of {{createAllProgress}})</span>
                      <svg v-if="createAllRunning" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </button>
                  </div>

                  <div class="mt-4 flex text-sm">
                    <span class="group inline-flex items-center text-gray-500 hover:text-gray-900">
                      <QuestionMarkCircleIcon class="h-16 w-16 text-gray-400 dark:text-gray-300 " aria-hidden="true" />
                      <span class="ml-2 dark:text-gray-300">
                        Creates a presentation for every online participant.
                        Uses the above time settings for everyone. These can be individually adjusted afterwards.
                      </span>
                    </span>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-shrink-0 px-4 py-4 flex justify-end">
          <button @click.prevent="requestClose" v-if="mode === 1" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
            Cancel
          </button>
          <button @click.prevent="deleteElement" v-if="mode === 0" type="button" class="bg-red-500 py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
            Delete
          </button>
          <button @click.prevent="confirmCreate" type="submit" class="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange hover:bg-orange-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
            {{getMainButtonText}}
          </button>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
import { XIcon } from '@heroicons/vue/outline'
import {
  LightningBoltIcon,
  LinkIcon,
  PlusIcon,
  PresentationChartBarIcon,
  QuestionMarkCircleIcon,
  UserIcon,
  CheckIcon,
  SelectorIcon

} from '@heroicons/vue/solid'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Switch, SwitchGroup, SwitchLabel, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import {ref} from 'vue'
import api from "@/api";

const types = [
  { name: 'Topic', type: 'TOPIC', desc: 'general topics', icon: PresentationChartBarIcon},
  { name: 'Presenter', type: 'PRESENTER', desc: 'user presentation', icon: UserIcon},
]

// eslint-disable-next-line no-unused-vars
const icons = [
  {name: "", icon: ""},
  {name: "", icon: ""},
  {name: "", icon: ""},
  {name: "", icon: ""},
]

const selected = ref(types[0]);

export default {
  name: "ItemEditor",
  data() {
    return {
      types: types,
      selected: selected,
      mode: 1, //1= normal //2= loading //3= done //mode 0 = update
      //CREATE NEW TOPIC
      nttimeinfinite: true,
      nticon: "SpeakerphoneIcon",
      nttarget: "",
      nttime: -1,
      //CREATE NEW PRESENTATION
      nptimeinfinite: false,
      nptarget: "Please select",
      nptargetname: null,
      nptime: 120,
      createAllRunning: false,
      createAllSize: 0,
      createAllProgress: 0
    }
  },
  methods: {
    confirmCreate() {
      if(this.mode === 0) {
        if(this.selected.type === "TOPIC") {
          this.updateTopic();
        } else if(this.selected.type === "PRESENTER") {
          this.updatePresentation();
        }
      } else {
        if(this.selected.type === "TOPIC") {
          this.createTopic();
        } else if(this.selected.type === "PRESENTER") {
          this.createPresentation();
        }
      }
    },
    createTopic() {
      if(this.nttime < 30 && !this.nttimeinfinite) return false;
      if(this.nttarget.length < 5) return false;
      let time = this.nttimeinfinite ? -1 : this.nttime;
      this.mode = 2;
      api.post("/queue/" + this.queue.id + "/items",
          {
            target: this.nttarget,
            type: "TOPIC",
            icon: this.nticon,
            time: time
          }).then(response => {
         if(response.status === 202) {
           console.log("successfully created new topic item:");
           console.log(response.data);
           this.mode = 3;
         }
      }).catch(error => {
        console.log("error while creating new topic:");
        console.log(error)
        this.mode = 1;
      });
    },
    createPresentation() {
      if(this.nptime < 30 && !this.nptimeinfinite) return false;
      if(this.nptarget === null) return false;
      if(this.nptarget === "Please select") return false;
      let time = this.nptimeinfinite ? -1 : this.nptime;
      this.mode = 2;
      api.post("/queue/" + this.queue.id + "/items",
          {
            target: this.nptargetname,
            content: "Presentation of",
            type: "PRESENTER",
            time: time,
            user: this.nptarget
          }).then(response => {
        if(response.status === 202) {
          console.log("successfully created new presenter item:");
          console.log(response.data);
          this.mode = 3;
        }
      }).catch(error => {
        console.log("error while creating new presenter:");
        console.log(error)
        this.mode = 1;
      });


    },
    createPresentationAll() {
      if(this.nptime < 30 && !this.nptimeinfinite) return false;
      let time = this.nptimeinfinite ? -1 : this.nptime;
      let hasErrors = false;
      if(this.mode === 1) {
        this.mode = 2;
        this.createAllRunning = true;
        this.createAllSize = this.userlist.length;
        for(let i = 0; i < this.userlist.length; i++) {

          api.get("/user/" + this.userlist[i]).then(response => {
            if(response.status === 200) {
              let targetName = response.data.name;
              api.post("/queue/" + this.queue.id + "/items",
                  {
                    target: targetName,
                    content: "Presentation of",
                    type: "PRESENTER",
                    time: time,
                    user: this.userlist[i]
                  }).then(response2 => {
                if(response2.status === 202) {
                  console.log("successfully created new presenter item:");
                  console.log(response2.data);
                  this.createAllProgress++;
                }
              }).catch(error => {
                console.log("error while creating new presenter:");
                console.log(error);
                hasErrors = true;
              });
            }
          }).catch(() => {
          });


        }
        if(!hasErrors) {
          this.mode = 3;
        } else {
          this.requestClose();
        }
      }
    },

    requestClose() {
      this.$emit("closerequest");
    },

    loadTopic() {
      if(this.queueitem !== null) {
        this.nttarget = this.queueitem.target;
        this.nttime = this.queueitem.time;
        this.nticon = this.queueitem.icon;
        this.nttimeinfinite = this.queueitem.time === -1;
      }
    },
    loadPresentation() {
      if(this.queueitem !== null) {
        this.nptarget = this.queueitem.user;
        this.nptime = this.queueitem.time;
        this.nptimeinfinite = this.queueitem.time === -1;
      }
    },
    updateTopic() {
      let time = this.nttimeinfinite ? -1 : this.nttime;
      let nitem = JSON.parse(JSON.stringify(this.queueitem));
      nitem.target = this.nttarget;
      nitem.time = time;
      nitem.icon = this.nticon;
      api.put("/queue/" + this.queue.id + "/items",
            nitem
      ).then(response => {
        if(response.status === 202) {
          this.requestClose();
        }
      });
    },
    updatePresentation() {
      let time = this.nptimeinfinite ? -1 : this.nptime;
      let nitem = JSON.parse(JSON.stringify(this.queueitem));
      nitem.target = this.nptargetname;
      nitem.time = time;
      nitem.user = this.nptarget
      api.put("/queue/" + this.queue.id + "/items",
          nitem
      ).then(response => {
        if(response.status === 202) {
          this.requestClose();
        }
      });
    },
    deleteElement() {
      console.log("item to delete: ");
      console.log(this.queueitem);
      api.delete("/queue/" + this.queue.id + "/item/" + this.queueitem.id
      ).then(response => {
        if(response.status === 202) {
          this.requestClose();
        }
      });
    }
  },
  emits: {
    closerequest: null
  },
  props: {
    userlist: {
      required: true,
      default: []
    },
    queue: {
      required: true
    },
    initialmode: {
      required: true,
      default: 1
    },
    queueitem: {
      default: null
    },
    initialtype: {
      default: null
    }
  },
  watch: {
    nttimeinfinite(nval) {
      if(!nval) this.nttime = 120;
    },
    nptarget(nval) {
      console.log("resolving");
      this.nptargetname = null;
      api.get("/user/" + nval).then(response => {
        if(response.status === 200) {
          this.nptargetname = response.data.name;
        }
      }).catch(() => {
      });
    },
    mode(nval) {
      if(nval === 3) {
        setTimeout( () => {
          this.createAllRunning = false;
          this.createAllProgress = 0;
          this.createAllSize = 0;
          this.requestClose();
        }, 1000)
      }
    }
  },
  computed: {
    getNPTarget() {
      if(this.nptargetname === null) return this.nptarget;
      return this.nptargetname;
    },
    getMainButtonText() {

      if(this.mode === 0 ) {
        return "Update"
      }

      if(this.mode === 1) {
        return "Create";
      } else if(this.mode === 2) {
        return "Loading";
      } else if(this.mode === 3) {
        return "Success";
      }
      return "unknown";
    },
    getUserList() {
      let nlist = [];
      this.userlist.forEach((element) => {
        api.get("/user/" + element).then(response => {
          if(response.status === 200) {
            nlist.push(response.data);
          }
        })
      })
      return nlist;
    },
    getHeaderText() {
      if(this.mode === 0) {
        if (this.selected.type === 'TOPIC') {
          return "Update a topic"
        } else if (this.selected.type === 'PRESENTER') {
          return "Update a presentation"
        } else {
          return "Unknown"
        }
      } else {
        if (this.selected.type === 'TOPIC') {
          return "Create a new topic"
        } else if (this.selected.type === 'PRESENTER') {
          return "Create a new presentation"
        } else {
          return "Unknown"
        }
      }
    },
  },
  mounted() {
    this.mode = this.initialmode;
    this.selected = types.find(e => e.type === this.initialtype);

    if(this.mode === 0) {
      if(this.initialtype === "TOPIC") {
        this.loadTopic();
      } else if(this.initialtype === "PRESENTER") {
        this.loadPresentation();
      }
    }

    this.getUserList.forEach(e => {
      console.log(e)
    })
  },
  components: {
    QuestionMarkCircleIcon,
    LinkIcon,
    PlusIcon,
    XIcon,
    LightningBoltIcon,
    SelectorIcon,
    CheckIcon,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    Switch,
    SwitchGroup,
    SwitchLabel,
  }
}
</script>

<style scoped>

</style>