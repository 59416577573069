<template>
  <Transition name="slide" mode="out-in">
  <div v-if="showTimer" class="w-32 h-16 text-center flex-wrap flex content-evenl items-center rounded-xl" :class="getTimerStyle">
    <span :class="getTimerTextStyle" class="inline-block align-center w-full">{{formatTime}} </span>
    <div v-if="showFinishButton" @click="ackFinish" class="absolute w-44 h-8 ml-36 text-center flex-wrap flex content-evenly rounded-md bg-orange hover:bg-orange-dark hover: cursor-pointer">
      <span class="inline-block align-center w-full">Finish presentation</span>
    </div>

  </div>
  </Transition>

</template>

<script>

import {mapGetters} from "vuex";

const runStyle = ["bg-orange", "text-white"];
const runLessTimeStyle = ["bg-orange-light", "text-white", "animate-pulse"];
const prepStyle = ["bg-indigo-500", "text-white", "animate-pulse"];
const stopStyle = ["bg-gray-500", "text-white", "opacity-0"];
const pausedStyle = ["bg-yellow-500", "text-white"];

const timerDefault = ["font-mono","text-3xl"];
const timerText = ["font-sans","text-xl"];

export default {
  name: "Timer",
  props: {
    seconds: {
      type: Number,
      required: true,
      default: -1
    },
    queue: {
      required: true
    },
    queueState: {
      required: true
    },
    queueItems: {
      required: true,
    },
  },
  data() {
    return {
      queueEndingSound: null,
      queueFinalEndingSound: null,
      queue1MinuteSound: null,
      queue30SecondsSound: null,
      endSoundPlaying: false,
      finalEndSoundPlaying: false,
      showingTitle: false
    }
  },
  methods: {
    ackFinish() {
      this.$emit("finishRequest");
    }
  },
  emits: {
    finishRequest: null
  },
  beforeMount() {
    this.queueEndingSound = new Audio(require("@/assets/presentation_ending.wav"))
    this.queueEndingSound.addEventListener("ended", () => {
      this.endSoundPlaying = false;
    });

    this.queueFinalEndingSound = new Audio(require("@/assets/presentation_end.wav"))
    this.queueEndingSound.addEventListener("ended", () => {
      this.finalEndSoundPlaying = false;
    });

    this.queue1MinuteSound = new Audio(require("@/assets/1minute.mp3"));
    this.queue30SecondsSound = new Audio(require("@/assets/30seconds.mp3"));
  },
  watch: {
    seconds(nval) {
      if(nval === 11 && !this.endSoundPlaying) {
        this.queueEndingSound.play();
        this.queueEndingSound.volume = 0.75;
        this.endSoundPlaying = true;
      }

      if(nval === 60) {
        this.queue1MinuteSound.play();
        this.queue1MinuteSound.volume = 0.75
      }

      if(nval === 30) {
        this.queue30SecondsSound.play();
        this.queue30SecondsSound.volume = 0.75
      }

      if(nval === 0) {
        let item = this.queueItems.find(item => item.state === "NOW");
        if(item !== undefined) {
          if(item.type === "PRESENTER") {
            this.queueFinalEndingSound.play();
            this.queueFinalEndingSound.volume = 0.8
          }
      }

      }

    },
    queueState(nstate) {
      if(nstate === "DONE" || nstate === "PREPARING") {
        console.log("trying to stop end audio")
        if(this.endSoundPlaying) {
          console.log("stopping ending sound")
          this.endSoundPlaying = false;
          this.queueEndingSound.pause();
          this.queueEndingSound.currentTime = 0;
        }

        if(this.finalEndSoundPlaying) {
          console.log("stopping final end sound");
          this.finalEndSoundPlaying = false;
          this.queueFinalEndingSound.pause();
          this.queueFinalEndingSound.currentTime = 0;
        }
      }
    }
  },
  computed: {
    showTimer() {
      if(this.queue === null) return false;
      if(this.queue.state === "PREPARING") return true;
      if(this.queue.state === "STOPPED" || (this.queue.state === "PAUSED" && this.seconds === -1)) return false;
      return this.seconds !== -1;
    },
    showFinishButton() {
      let item = this.queueItems.find(item => item.state === "NOW");
      if(item === null || item === undefined) return false;
      if(item.user === undefined) return false;
      return item.user === this.loginUser.id;

    },
    getTimerTextStyle() {
      if(this.queue.state === "PREPARING") return timerText;
      return timerDefault;
    },
    formatTime() {
      if(this.queue.state === "PREPARING") return "wait..";
      let minutes = ~~(this.seconds / 60);
      let seconds = this.seconds % 60;
      if(minutes < 10) minutes = "0" + minutes;
      if(seconds < 10) seconds= "0" + seconds;
      return minutes + ":" + seconds;
    },
    getTimerStyle() {
      if(this.queue.state === "RUNNING") {
        if(this.seconds > 10) {
          return runStyle;
        } else {
          return runLessTimeStyle
        }
      } else if(this.queue.state === "PAUSED") {
        return pausedStyle;
      } else if(this.queue.state === "STOPPED") {
        return stopStyle;
      } else if(this.queue.state === "PREPARING") {
        return prepStyle;
      }
      return stopStyle;
    },
    ...mapGetters(["loginUser"])
  }
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s ease, opacity 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>