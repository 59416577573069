
<template>
  <div class="row mt-2">
    <h1 class="text-center text-md dark:text-white text-black"><UsersIcon class="w-5 h-5 dark:text-white text-black inline -mt-1"/> {{users.length}}</h1>
    <div class="flex flex-col items-center">
      <div class="w-11/12 h-0" >
        <UserCard v-for="user in users" :key="user" :id="user" :is-admin="room.administrators.includes(user)" status="ONLINE"/>
      </div>
    </div>
  </div>
</template>

<script>

import UserCard from "@/components/UserCard";
import {UsersIcon} from "@heroicons/vue/solid/esm";

export default {
  name: "transition-example-2",
  display: "Transitions",
  order: 7,
  components: {
    UserCard,
    UsersIcon,
  },
  props: {
    users: {
      default: [],
      required: true
    },
    room: {
      required: true
    }
  },
  methods: {
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style scoped>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>