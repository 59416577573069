<template>
  <div class="h-full w-2/12 dark:bg-gray-900 bg-gray-200 flex">
    <UserList v-if="room !== null" :users="userlist" :room="room" class="overflow-y-auto w-full" />
  </div>
  <div class="h-full flex-grow">
    <div class="flex justify-center">
      <Timer @finishRequest="finishPresentation" v-if="queue !== null" class="absolute mt-2" :seconds="queue.timeleft" :queue="queue" :queue-items="queueItems" :queue-state="queue.state" />
    </div>
    <div class="flex justify-center">
      <transition name="slide" mode="out-in">
        <TopicAnnouncement v-if="currentItem !== null && topicAnnouncementVisible" :queue-item="currentItem" class="z-10 absolute bg-orange top-64" />
      </transition>
    </div>

    <iframe @load="pushOnLoad" class="h-full w-full" :src="room.url" frameborder="0" allow="camera; microphone; display-capture; autoplay; clipboard-write; sound"></iframe>
  </div>
  <div class="h-full w-72 dark:bg-gray-900 bg-gray-200 flex justify-center overflow-hidden">
    <transition name="slide" mode="out-in">
      <div v-if="!editorOpen" class="flex flex-col flex-grow items-center h-full">
        <ItemQueue @clickItem="editItem" v-if="queueItems !== null && queue !== null && room !== null" @updateTimeLine="updateTimeLine" :queueitems="queueItems" :queue="queue" :room="room" class="overflow-y-auto" />
        <QueueControl @editoropen="openeditor" v-if="queue !== null && isAdmin" :state="queue.state" :queue="queue"></QueueControl>
      </div>
      <div v-else class="flex flex-col flex-grow items-center h-full">
        <ItemEditor @closerequest="closeeditor" :userlist="userlist" :queue="queue" :queueitem="editorModeElement" :initialmode="editorModeNum" :initialtype="editorType"/>
      </div>
    </transition>
  </div>
</template>

<script>
import UserList from "@/components/UserList";
import Timer from "@/components/Timer";
import ItemQueue from "@/components/ItemQueue";
import QueueControl from "@/components/QueueControl";
import {mapGetters} from "vuex";
import api from "@/api";
import ItemEditor from "@/components/ItemEditor";
import TopicAnnouncement from "@/components/TopicAnnouncement";

export default {
  name: "MeetingView",
  components: {TopicAnnouncement, ItemEditor, QueueControl, ItemQueue, Timer, UserList},
  data() {
    return {
      userlist: [],
      queue: null,
      queueItems: [],
      connection: null,
      topicAnnouncementVisible: false,
      editorOpen: false,
      editorType: null,
      editorModeNum: 0,
      editorModeElement: null,
      intervalhb: null
    }
  },
  watch: {
    queueState(nval, oval) {
      if(oval === "PREPARING" && nval === "RUNNING") {
        this.fireTopicAnnouncement();
      }
    }
  },
  props: {
      iframesource: {
        type: String,
        required: true
      },
      room: {
        type: Object,
        required: true
      }
  },
  computed: {
    isAdmin() {
      return this.room.administrators.includes(this.loginUser.id);
    },
    queueState() {
      if(this.queue === null) return null;
      return this.queue.state;
    },
    currentItem() {
      let item = this.queueItems.find(item => item.state === "NOW");
      if(item === null || item === undefined) return null;
      return item;
    },
    ...mapGetters(["loginUser"])
  },
  methods: {
    pushOnLoad() {
      this.$emit("onframeload");
    },
    fireTopicAnnouncement() {
      if(!this.topicAnnouncementVisible) {
        console.log("showing topic announcement (if new topic is present)")
        this.topicAnnouncementVisible = true;
        setTimeout(() => {
          this.topicAnnouncementVisible = false;
        }, 5000)
      }
    },
    finishPresentation() {
      let url = "/queue/" + this.queue.id + "/next";
      api.put(url).then((response =>  {
        if(response.status === 202) {
          console.log("Successfully send change request to " + url);
        }
      })).catch(error => {
        console.log("Error while requesting queue state change");
        console.log(error);
      });
    },
    openeditor(type) {
      this.editorType = type;
      this.editorModeNum = 1;
      this.editorOpen = true;
      this.editorModeElement = null;
    },
    editItem(item) {
      this.editorModeNum = 0;
      this.editorType = item.type;
      this.editorModeElement = item;
      this.editorOpen = true;
    },
    closeeditor() {
      this.editorOpen = false;
    },
    updateTimeLine(e) {
      console.log("* requesting position exchange of pos: " + e.dragFrom + " to: " + e.dragTo + " of itemID: " + e.draggedItemId);
      if(this.connection !== null && this.queue !== null) {
        this.connection.send(JSON.stringify({action: "queueMoveItem", item: e.draggedItemId, pos: e.dragTo, queue: this.queue.id}));
      }
    },
    syncQueueItems() {
      api.get("/queue/" + this.room.queue+"/items").then(response => {
        if(response.status === 200) {
          this.queueItems = response.data;
        }
      });
    }
  },
  emits: {
    onframeload: null,
    canaddself: null,
  },
  mounted() {

    setTimeout(() => {
      if(api.get("/queue/" + this.queue.id + "/items/addself").then(value => {
        if(value.status === 200) this.$emit("canaddself");
      }));
    }, 5000)

    console.log("Opening socket to " + 'wss://meetserver.paj-gps.de/socket/' + this.room.id + "/" + this.loginUser.id);
    this.connection = new WebSocket('wss://meetserver.paj-gps.de/socket/' + this.room.id + "/" + this.loginUser.id);
    //this.connection = new WebSocket('ws://localhost:8080/socket/' + this.room.id + "/" + this.loginUser.id);
    this.connection.onopen = () => {
      console.log("Starting HB");
      this.intervalhb = setInterval(() => {
        this.connection.send(JSON.stringify({action: "hb"}));
      }, 5000);
    }


    this.connection.onmessage = (event) => {
      let msg = JSON.parse(event.data);
      console.log(msg);
      if(msg.action === "userlist") this.userlist = msg.users;
      if(msg.action === "addUser") {if(!this.userlist.find(e => e === msg.user)) this.userlist.push(msg.user);}
      if(msg.action === "removeUser") {if(this.userlist.find(e => e === msg.user)) this.userlist.splice(this.userlist.findIndex(e => e === msg.user), 1)}
      if(msg.action === "queueUpdateTimeLeft") {if(this.queue !== null) {this.queue.timeleft = msg.time}}
      if(msg.action === "queueUpdateState") {if(this.queue !== null) {this.queue.state = msg.state;}}
      if(msg.action === "queueAddItem") {if(this.queueItems !== null) {this.queueItems.push(JSON.parse(msg.item))}}
      if(msg.action === "queueDeleteItem") {if(this.queueItems !== null) {let rpos = getQueuePos(msg.item, this.queueItems); if(rpos !== -1) {this.queueItems.splice(rpos, 1)}}}
      if(msg.action === "queueDeleteAllItems") {if(this.queueItems !== null) {this.queueItems = []}}
      if(msg.action === "queueUpdateItem") {if(this.queueItems !== null) {this.queueItems[this.queueItems.findIndex(qitem => qitem.id === JSON.parse(msg.item).id)] = JSON.parse(msg.item)}}
      if(msg.action === "queuePosRefresh") {if(this.queueItems !== null) {msg.queue.forEach(item => {this.queueItems.find(qitems => qitems.id === item.id).pos = item.pos})}}
      if(msg.action === "queueUpdateItemState") {if(this.queueItems !== null) {this.queueItems.find(qitem => qitem.id === msg.item).state = msg.state}}
      if(msg.action === "warning") {if(msg.message === "your queue move request is faulty") {this.syncQueueItems()}}
      if(msg.action === "meetingend") {this.connection.close(); this.pushOnLoad()}
    }
  },
  beforeUnmount() {
    if(this.connection !== null) {
      this.connection.close();
      clearInterval(this.intervalhb);
    }
  }
  ,
  beforeMount() {
    api.get("/queue/" + this.room.queue).then(response => {
        if(response.status === 200) {
          this.queue = response.data;
        }
    });

    this.syncQueueItems();
  },
}

function getQueuePos(id,queueitems) {
  console.log("SIZE BEFORE: " + queueitems.length);
    for(let i = queueitems.length -1; i >= 0; i--) {

      console.log("Checking: ID: " + queueitems[i].id + " against: " + id);
      console.log(queueitems[i]);
      if(queueitems[i].id === id) {
        console.log("returning pos: " + i)
        return i;
      }
    }
  return -1;
}
</script>

<style scoped>

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.6s ease, opacity 0.4s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>