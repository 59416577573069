<template>
  <div class="flow-root w-11/12 mt-2 h-0 mb-5 flex-grow">
    <div class="flex flex-row justify-center">
      <h1 class="text-center text-md dark:text-white text-black w-full">{{getDoneElements}} <ClipboardCheckIcon class="w-5 h-5 dark:text-white text-black inline -mt-1"/></h1>
      <h1 class="text-center text-md dark:text-white text-black w-full">{{(queueitems.length - getDoneElements)}} <ClipboardCopyIcon class="w-5 h-5 dark:text-white text-black inline -mt-1"/></h1>
    </div>
    <ul class="mt-2 ml-2">

      <draggable
          class="list-group flex flex-col items-center"
          tag="transition-group"
          :move="onMoveEvent"
          :component-data="{
          tag: 'ul',
          type: 'transition-group',
          name: !drag ? 'flip-list' : null
        }"
          v-model="listElements"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          item-key="id"
      >
        <template #item="{ element, index }" >
          <div @click="emitItemClick(element)" class="relative pb-8 w-full">
            <span v-if="(index !== listElements.length - 1)" class="absolute top-4 left-4 -ml-px h-full w-0.5 dark:bg-gray-200 bg-white" aria-hidden="true" />
            <div class="relative flex space-x-3">
              <div>
              <span class="h-8 w-8 rounded-full flex items-center justify-center ring-2 dark:bg-gray-700" :class="getQueueStyle(element.state)">
                <QueueItemIcon :icon="element.icon" class="h-5 w-5" aria-hidden="true" :class="getQueueIconStyle(element.state)" />
              </span>
              </div>
              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p class="text-sm text-gray-500">
                    {{ element.content }} <span class="font-medium text-gray-900 dark:text-white">&nbsp;{{ element.target }}</span>
                  </p>
                  <div class="">
                    <QueueClock v-if="element.time !== -1 && element.state !== 'NOW'" :seconds="element.time" class=""/>
                    <QueueClock v-if="element.time !== -1 && element.state === 'NOW'" :seconds="queue.timeleft" class=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </draggable>

      <!--<div v-for="(element, index) in getOptimizedListV3" :key="element.id" class="relative pb-8 w-full">
        <span v-if="(index !== getOptimizedListV3.length - 1)" class="absolute top-4 left-4 -ml-px h-full w-0.5 dark:bg-gray-200 bg-white" aria-hidden="true" />
        <div class="relative flex space-x-3">
          <div>
              <span class="h-8 w-8 rounded-full flex items-center justify-center ring-2" :class="getQueueStyle(element.status)">
                <component :is="element.icon" class="h-5 w-5" aria-hidden="true" :class="getQueueIconStyle(element.status)"/>
              </span>
          </div>
          <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div>
              <p class="text-sm text-gray-500">
                {{ element.content }} <a :href="element.href" class="font-medium text-gray-900 dark:text-white">&nbsp;{{ element.target }}</a>
              </p>
              <div class="">
                <QueueClock v-if="element.time !== -1" :seconds="element.time" class=""/>
              </div>
            </div>
          </div>
        </div>
      </div>-->

      <!--<li v-for="(event, eventIdx) in timeline" :key="event.id">
        <div class="relative pb-8">
          <span v-if="(eventIdx !== timeline.length - 1)" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div>
              <span :class="[event.iconBackground, 'h-8 w-8 rounded-full flex items-center justify-center ring-2 ring-gray-900']">
                <component :is="event.icon" class="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
              <div>
                <p class="text-sm text-gray-500">
                  {{ event.content }} <a :href="event.href" class="font-medium text-gray-900 dark:text-white">&nbsp;{{ event.target }}</a>
                </p>
                <div class="">
                <QueueClock v-if="event.time !== -1" :seconds="event.time" class=""/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li> -->
    </ul>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { CheckIcon, ThumbUpIcon, UserIcon, UsersIcon, CurrencyEuroIcon, SpeakerphoneIcon } from '@heroicons/vue/solid'
import { ClipboardCheckIcon, ClipboardCopyIcon} from '@heroicons/vue/outline'
import QueueClock from "@/components/QueueClock";
import draggable from "vuedraggable";
import QueueItemIcon from "@/components/QueueItemIcon";
import {mapGetters} from "vuex";

const now = ['dark:ring-orange','ring-orange bg-white'];
const done = ['dark:ring-gray-500','ring-white','bg-white'];
const pending = ['dark:ring-gray-900','ring-white bg-gray-200'];

const inow = ['dark:text-white','text-black','animate-pulse'];
const idone = ['dark:text-white','text-black'];
const ipending = ['dark:text-white','text-black'];

export default {
  data() {
    return {
      dragFrom: 0,
      dragTo: 0,
      draggedItemId: null
    }
  },
  display: "Transitions",
  components: {
    QueueItemIcon,
    QueueClock,
    ClipboardCheckIcon,
    ClipboardCopyIcon,
    draggable
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    listElements: {
      get () {
        let list = [...this.queueitems];
        return list.sort((a,b) => {
          return a.pos - b.pos;
        })
      },
      set (value) {
        value.dragFrom = this.dragFrom;
        value.dragTo = this.dragTo;
        value.draggedItemId = this.draggedItemId;
        this.$emit('updateTimeLine', value)
      }
    },
    getDoneElements() {
      let done = 0;
      this.queueitems.forEach((e) => {
        if(e.state === "DONE") done++;
      });
      return done;
    },
    ...mapGetters(["loginUser"])
    },
    props: {
      queueitems: {
        required: true,
        default: []
      },
      queue: {
        required: true,
        default: []
      },
      room: {
        required: true
      }
    },
    emits: {
      updateTimeLine: null,
      clickItem: null
    },
  methods: {
    emitItemClick(item) {
      if(this.room.administrators.includes(this.loginUser.id) && item.state !== "DONE" && item.state !== "NOW") {
        this.$emit('clickItem', item)
      }
    },
    getQueueStyle(status) {
      if(status === "NOW") return now;
      if(status === "PENDING") return pending;
      return done;
    },
    getQueueIconStyle(status) {
      if(status === "NOW") return inow;
      if(status === "PENDING") return ipending;
      return idone;
    },
    onMoveEvent(event) {
      if(!this.room.administrators.includes(this.loginUser.id)) return false;
      if(event.relatedContext.element.state === 'NOW' || event.relatedContext.element.state === 'DONE' || event.relatedContext.element.target === "Finish Line") return false;
      var dragSuccess = (event.draggedContext.element.state !=='NOW' && event.draggedContext.element.state !=='DONE' && event.draggedContext.element.target !== "Finish Line");
      if(dragSuccess) {
        this.dragFrom = event.draggedContext.element.pos;
        this.dragTo = event.relatedContext.element.pos;
        this.draggedItemId = event.draggedContext.element.id;
      }
      return (dragSuccess);
    }
  }
}
</script>

<style scoped>
.button {
  margin-top: 35px;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>